import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-dark text-white text-center py-4 my-footer">
      <p className="mb-1">ParkEz Inc.</p>
      <p className="mb-1">1234 Park Street, Suite 567</p>
      <p className="mb-1">Stamford, CT 06902</p>
      <p className="mb-1">Phone: (203) 123-4567</p>
      <p className="mb-1">Email: support@parkez.ai</p>
    </footer>
  );
};

export default Footer;